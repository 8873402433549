<script setup lang="ts">
import type { TArticle } from '@tl-ui/components/ArticleCard.vue'
import type { TJobBoardArticle } from '@tl-strapi/types/TJobBoardArticle'

const toLocalePath = useLocalePath()

const props = defineProps({
  heading: {
    type: String as PropType<string>,
    default: ''
  },
  headingTo: {
    type: String as PropType<string>,
    default: ''
  },
  articles: {
    type: Array as PropType<TJobBoardArticle[] | null>,
    default: null
  },
  loading: {
    type: Boolean
  }
})

const computedArticles = computed<TArticle[]>(() => {
  return props.loading || !props.articles ? [] : strapiArticlesToTArticles(props.articles)
})

const rawArticlesMap = computed<Record<string, TJobBoardArticle>>(() => {
  return !props.articles
    ? {}
    : props.articles.reduce(
        (acc, article) => {
          acc[article.id] = article
          return acc
        },
        {} as Record<string, TJobBoardArticle>
      )
})

function handleMouseEnter(id: string | number) {
  usePreloadedArticle().value = rawArticlesMap.value[id]
  usePrefetchArticleImage(rawArticlesMap.value[id])
}
function handleMouseLeave() {
  usePreloadedArticle().value = null
}
</script>

<template>
  <div v-if="articles" class="right-side-list flex flex-col gap-[1.88rem]">
    <div v-if="heading" class="flex flex-row items-center justify-between">
      <div class="text-h4 font-medium">
        {{ heading }}
      </div>
      <ButtonBlackButton
        :text="$t('common.list.seeAll')"
        variant="text"
        size="small"
        tabindex="-1"
        mode="navigation"
        :to="toLocalePath(headingTo)"
      >
        <template #left-icon>
          <IconsFigmaArrowForwardOutlined />
        </template>
      </ButtonBlackButton>
    </div>
    <div v-if="loading">
      <div class="flex flex-col gap-[1.25rem]">
        <SkeletonLoader variant="Text" />
        <SkeletonLoader variant="Text" />
      </div>
    </div>
    <div v-else class="flex flex-col gap-[1.25rem]">
      <template v-for="state in computedArticles" :key="state.id">
        <ArticleCard
          v-if="state.thumbnail"
          :article="state"
          variant="SideList"
          heading-font-size="text-h5m md:text-h5"
          @mouseenter="() => handleMouseEnter(state.id)"
          @mouseleave="handleMouseLeave"
        >
          <template #article-img="slotProps">
            <ArticleCardImage
              :image="slotProps.thumbnail"
              alt="Article Thumbnail (TechJobAsia)"
              sizes="sm:120px"
              :crop-to-ratio="1.333"
              :wrapper-dimensions="{
                width: 120
              }"
              :lazy="true"
              :quality="80"
            />
          </template>
        </ArticleCard>
      </template>
    </div>
  </div>
</template>
